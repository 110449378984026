<script>
import "chart.js/auto";
import { Doughnut } from "vue-chartjs";

export default {
  name: "Doughnut",
  extends: Doughnut,
  comments: {
    Doughnut,
  },
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
